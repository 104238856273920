.address {
    input[name$="_address1"] {
        & + .melissa-address {
            position: relative;
            height: 0;
            width: 100%;
            @media screen and (min-width: 880px) {
                width: 340px;
            }
            .melissa-address-list {
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                top: -2px;
                left: 0;
                z-index: 10;
                background-color: $gray-white;
                border: 1px solid $black;
                width: 100%;
                box-sizing: border-box;
                list-style-type: none;
                padding: unset;
                padding-left: 12px;
                line-height: 22px;
                cursor: pointer;
                font-size: 14px;
            }
            .melissa-address-list-item {
                line-height: 20px;
                cursor: pointer;
                &:hover ,
                &:focus {
                    background-color: #3d91ff;
                    color: $white;
                }
            }
        }
    }

    .form-caption{
        &.address1{
            position: static !important;
        }
    }
}